



































































































































































































































@import '../../../assets/scss/common/variables';
.with-register-modal {
  .modal-mask {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    margin: auto;
    &:before {
      content: '';
      display: block;
      height: 8px;
      width: 100%;
    }
  }
  .modal-wrapper {
    display: block;
  }
  .modal-body {
    margin: 0;
  }
}

.with-register-modal-content {
  min-height: 50vh;
  & [class^='div-scroll'] {
    height: 50vh;
  }
}
.with-register-modal-check {
  margin-top: -30px;
}
